<!-- <div class="alert-position">
  <alert></alert>
</div> -->
<div class="black-left" [routerLink]="['/search']"></div>
<div class="side-form">
  <p><a class="back-arrow" [routerLink]="['/search']"><i class="fa fa-times" aria-hidden="true"></i></a></p>
    <alert></alert>
      <div *ngIf="costumes">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="white-card">
                <div class="description-text">
                  <p><strong>{{costumes.name}}</strong>: {{costumes.description}}</p>
                </div>
                <div class="row">
                  <div class="col-lg-5 col-md-12">
                    <div class="pull-right width-60">
                      <img class="main-image" [src]="firstImage" alt="">
                    </div>

                    <div class="images-div">
                      <div *ngIf="secondImage">
                        <img class="sub-image" (click)="switchSecond()" [src]="secondImage" alt="">
                      </div>
                      <div *ngIf="thirdImage">
                        <img class="sub-image bottom" (click)="switchThird()" [src]="thirdImage" alt="">
                      </div>
                    </div>
                    
                  </div>
                  <div class="col-lg-7 col-md-12" *ngIf="contactview">
                    <div  class="dark-bg">
                      <div class="contain-message" *ngIf="contactview" [@minislideInOutReverseAnimation]>
                        <div >
                          <form name="form" (ngSubmit)="f.form.valid && sendForm()" #f="ngForm" novalidate>
                                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !message.valid }">
                                    <textarea placeholder="Message" type="message" class="form-control" name="message" [(ngModel)]="model.message" #message="ngModel" required>
                                    </textarea> 
                                    <div *ngIf="f.submitted && !message.valid" class="help-block">Message is required</div>
                                </div>
                                <div class="form-group send-btns">
                                   <button [disabled]="loading" class="btn btn-primary">Send Message</button>
                                    <button type="button" (click)="setFalse()" class="btn btn-primary">View Costume Details</button>
                                      <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                </div>
                            </form>
                          </div> <!-- end mini slide out -->
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-7 col-md-12" *ngIf="!contactview">
                    <div class="row">
                      <div class="col-lg-6 col-md-12">
                        <ul class="listing-item">
                          <li>Size: {{costumes.costumesize}}</li>
                          <li>Age: {{costumes.costumeage}}</li>
                          <li>Gender: {{costumes.gender}}</li>
                          <li>City: {{costumes.city}}</li>
                          
                        </ul>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <ul class="listing-item right-margin">
                          <li>State: {{costumes.state}}</li>
                          <li>Zipcode: {{costumes.zipcode}}</li>
                          <li>Original Price: ${{costumes.originalprice}}</li>
                          <li>Listing Price: ${{costumes.listingprice}}</li>
                        </ul>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 contact-btn">
                        <button class="btn btn-primary extra-margin" (click)="contactClick()">Contact Seller</button>
                      </div>
                    </div>
                  </div>
                </div> <!-- end row -->
              </div><!--  end white card -->
            </div> <!-- end col-md-12 -->  
          </div> <!--  end row -->
        </div> <!-- end container -->
      </div> <!-- end IF -->
</div>
