<footer class="clearfix">
	<nav>
		<ul>
			<!-- <li><a [routerLink]="['/']">Home</a></li> -->
	     <!--    <li *ngIf="authService.isLoggedIn()">
			  <a [routerLink]="['/costumes']">My Account</a>
			</li>
			<li *ngIf="authService.isLoggedIn()">
			  <a [routerLink]="['/login']">Logout</a>
			</li>
			<li *ngIf="!authService.isLoggedIn()">
			  <a [routerLink]="['/login']">Login</a>
			</li> -->
			<!-- <li *ngIf="!authService.isLoggedIn()">
			  <a [routerLink]="['/register']">Register</a>
			</li> -->
			<li><a [routerLink]="['/seller-tips']">Seller Tips</a></li>
			<li><a [routerLink]="['/buyer-tips']">Buyer Tips</a></li>
			<li><a href="https://www.costumegrab.com/blog/">Blog</a></li>
			<li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
			<li><a [routerLink]="['/terms-of-service']">Terms of Service</a></li>
		</ul>
	</nav>
</footer>