<div class="grey-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="white-card">
					<h1>Tips for Sellers</h1>
					<div class="separator separator-danger">✻</div> 
					<div class="seller-faq-contain">
						<h2>Simple Tips For Selling Online</h2>

						<p>Buying and selling online can be a very fun and profitable experience, but above all, when buying or selling any items online, you need to use your common sense.  While Costume Grab is currently doing all we can to eliminate scammers and spammers from our website, bother buyers and sellers need to do their part in avoiding these types of people.  Here are some tips for both sellers and buyers.</p>

						<p>1. <span class="bold-700">Never</span> accept personal checks, certified checks, cashiers checks, or money orders from a buyer.</p>

						<p>2.  The best option is to use a secure payment method such as <a href="https://www.paypal.com/">PayPal</a>. We recommend you use <a href="https://www.paypal.com/">PayPal</a> to accept any payments (you can read how PayPal works <a href="https://www.paypal.com/webapps/mpp/pay-online">here</a>).  If a buyer claims they have already sent money to your PayPal account, make sure to check your account first. If you do not see the money in your account, the buyer has not paid yet.  If you see a "pending payment", that <span class="bold-700">DOES NOT</span> mean the buyer has paid you the money yet. Make sure to verify the money is in your account and the payment is not still "pending".</p>

						<p>3. Most scammers tend to operate by email or text. A clear sign of a scammer is someone that claims to be unavaliable by phone. If possible, a video conference is a great way to distinguish real buyers from scammers.</p>

						<p>4. Poor grammar, poor spelling, and vague interest can also be signs of scammers.</p>

						<p>5. If you are contacted by a scammer, the best course of action is to ignore the email and mark the email as "spam" in your inbox.</p>

						<p>6. Most people have heard of the "Prince of Nigeria" scam, but scammers often come from ALL countries of the world.</p>

						<p>7. <span class="bold-700">NEVER</span>, under any circumstances, should you give out your personal information such as bank account numbers, credit cards, social security number, account passwords, etc.</p>

						<p>8. If you choose to meet a buyer (or seller) in person to exchange money, arrange the meeting in a public area. A great choice is a local coffee shop. Consider bringing a friend with you.</p>

						<h2>Simple Listing Tips For Sellers</h2>

						<p>Make sure to <span class="bold-700">price it right!</span> Overpriced items are unlikely to sell.</p>

						<p>1. The seller ultimately determines the price depending on the condition of the item. At Costume Grab we recommend that you list your item at no more than 25-50% of the original listing price.</p>

						<p>2. <span class="bold-700">DO NOT</span> list poor quality items or items you would consider "junk". These items <span class="bold-700">WILL NOT SELL</span>.  Buyers do not want old or poor quality items that a seller may be trying to remove from their closet or storage bins. Buyers are looking for quality costumes and accessories that they can enjoy for years to come.</p>

						<p>3.  If you have a very high quality costume that has never been worn, you have a much higher chance of selling at a higher price.  If even worn once, the item should be considered used.</p>

						<p>4. <span class="bold-700">ITEMS SHOULD BE CLEAN, WITHOUT RIPS, TEARS, STAINS, OR OTHERWISE UNSIGHTLY FEATURES</span>.  Any issues with an item should be clearly stated in the description.</p>

						<p>5. Items that are more than a few years old rarely sell unless they have been very well taken care of or are by a high quality manufacturer.</p>

						<p>6. If you purchased your item for a high price tag and are able to show the proof of that, you are more likely to sell the item for a higher price.</p>

						<h3>Show Off Your Costume!</h3>

						<p>1. Post clear photos of your costume (or item), preferably of you (or anyone else) <span class="bold-700">wearing</span> the item! Clear photos against the wall or draped across a bed are OK, but the best selling items are worn by sellers and showcased to buyers.</p>

						<p>2. Be very accurate in your description. Use the same words and phrases that you would use if you were looking for the item yourself. Feel free to use keywords in the description as they can help the buyer while using the search box.</p>

						<p>3. Be accurate about any measurements or flaws. A flawed costume is OK as long as the quality is still intact. By being honest with any issues, buyers will be more likely to purchase your costumes.</p>

						<h3>Who Will Pay For Shipping?</h3>

						<p>As the seller, you can determine who pays for shipping costs (the buyer, you, or a shared cost agreement).  Sellers often specify that shipping is a buyer's cost and include it in the asking price of the costume. Shipping costs vary and depend on the size and weight of the costume. USPS offers priority mail flat rate for heavier items and FedEx or UPS may be less expensive for ligher items inside the U.S.. Please make sure to provide the buyer with the shipping tracking information.</p>

						<h3>Should I Offer a Return Policy?</h3>

						<p>It's up to you to offer a return policy. If you choose to do so, you should make it clear to buyers that they should: (1) notify you within 24 of receipt that they wish to return the item; (2) they should ship the item back immediately; (3) they should be required to pay a return fee of 15% of the purchase price of the item.</p>



<!-- WHAT DOES IT COST TO LIST AN ITEM FOR SALE? 

1. It's only $5.00 to list an item for sale for a full year! And, there is no commission or percentage taken on your sale!

2. For even more exposure, feature your item for only $2.99 for 7 days or for only $7.99 for 30 days. After the featured period ends, your item will remain on the website for a year. (See more details regarding Featured Items[AW1] )
 -->
 
						
					</div>
				</div>
			</div>
		</div>
	</div>
</div>	
