<div class="alert-position">
<alert></alert>
</div>
<div class="default-bg"> 
    <div class="container">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="register-box">
                    <alert></alert>
                    <h2>Register</h2>
                    <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
                        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                            <input type="text" placeholder="Username" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required />
                            <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
                        </div>
                        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                            <input type="text" placeholder="Email" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" required />
                            <div *ngIf="f.submitted && !email.valid" class="help-block">Email is required</div>
                        </div>
                        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                            <input type="password" placeholder="Password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
                            <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                        </div>
                        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                            <input type="password" placeholder="Confirm Password" class="form-control" name="confirm_password" [(ngModel)]="model.confirm_password" #confirm_password="ngModel" required />
                            <div *ngIf="f.submitted && !confirm_password.valid" class="help-block">Confirm Password is required</div>
                        </div>
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-primary">Register</button>
                            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            <a [routerLink]="['/login']" class="btn btn-link">Cancel</a>
                        </div>
                        <div *ngIf="errors">
                          <ul *ngFor="let error of errors">
                            <li class="error-list">{{error}}</li>
                          </ul>
                        </div>
                    </form>
                </div> <!-- end register-box -->
            </div>
        </div>
    </div>
</div>
