<header class="desktop-nav">
	<div class="header-bg">
		<div class="logo">
			<a [routerLink]="['/']">
				<img src="../../../assets/images/CG_logo.jpg" alt="Costume Grab">
			</a>
		</div>
		<nav>
			<ul>
				<li><a [routerLink]="['/search']">Search</a></li>
		        <li *ngIf="authService.isLoggedIn()">
				  <a [routerLink]="['/costumes']">My Account</a>
				</li>
				<li *ngIf="authService.isLoggedIn()">
				  <a [routerLink]="['/login']">Logout</a>
				</li>
				<li *ngIf="!authService.isLoggedIn()">
				  <a [routerLink]="['/login']">Login</a>
				</li>
				<li *ngIf="!authService.isLoggedIn()">
				  <a [routerLink]="['/register']">Register</a>
				</li>
				<li><a [routerLink]="['/faq']">Faq</a></li>
				<li><a [routerLink]="['/contact']">Contact</a></li>
			</ul>
		</nav>
	</div>
</header>

<div class="mobile-nav">
	<div class="white-bg clearfix">
		<div id="toggle" (click)="toggleNav()" [class.toggled]="navToggle === true">
			<ul>
				<li>
					<span class="line l1"></span>
					<span class="line l2"></span>
					<span class="line l3"></span>
				</li>
			</ul>
		</div>
		<div class="logo">
			<a [routerLink]="['/']">
				<img src="../../../assets/images/CG_logo.jpg" alt="Costume Grab">
			</a>
		</div>
	</div>
	<section id="full-screen-nav" class="panel" *ngIf="navToggle === true" [@HeaderSlideInOutAnimation] [class.active]="navToggle === true">
		<nav id="nav">
			<ul>
				<li>
					<a (click)="toggleNav()" [routerLink]="['/']">Home
					</a>
				</li>
				<li>
					<a (click)="toggleNav()" [routerLink]="['/search']">Search
					</a>
				</li>
				<li *ngIf="authService.isLoggedIn()">
					<a (click)="toggleNav()" [routerLink]="['/costumes']">My Account
					</a>
				</li>
				<li *ngIf="authService.isLoggedIn()">
					<a (click)="toggleNav()" [routerLink]="['/login']">Logout
					</a>
				</li>
				<li *ngIf="!authService.isLoggedIn()">
					<a (click)="toggleNav()" [routerLink]="['/login']">Login
					</a>
				</li>
				<li *ngIf="!authService.isLoggedIn()">
					<a (click)="toggleNav()" [routerLink]="['/register']">Register
						<!-- <span class="desc">
							let's chat about your next 
							project?
						</span> -->
					</a>
				</li>
				<li>
					<a (click)="toggleNav()" href="https://www.costumegrab.com/blog/" >Blog
					</a>
				</li>
			</ul>
		</nav>
	</section>
</div>

