<div class="contain-overlay">
    <div class="overlay">
    <div class="darker-overlay"></div>
        <h1>Costume Grab</h1>
        <p>The Quickest Way to Grab a New Costume!</p>
    </div>
</div>
<div class="white-background clearfix">
    <div class="dark-bg">
         <div *ngIf="costumes">
            <div class="hide-slide" [ngClass]="{'active':activateIt}">
                <div class="hidden-body" style="width: 100%;" [@miniHomeSlideInOutAnimation] *ngIf="activateIt">
                    <div class="container">
                        <div class="row">
                            <ng-container *ngFor="let costume of costumes" >
                                 <div class="col-md-4 text-center">
                                    <div class="costume-card">
                                        <a [routerLink]="['/search/view/', costume.id]"><img style="width:100%;max-width: 200px;" src="{{costume.image}}" alt="Costume Grab"></a>
                                        <h4 class="costume-name">{{(costume.name.length>13)? (costume.name | slice:0:13)+'..':(costume.name) }}</h4>
                                        <div class="costume-footer">
                                            <div class="price">
                                              <h4>${{costume.listingprice}}</h4>
                                            </div>
                                            <div class="stats pull-right">
                                              <p class="category"><i class="fa fa-map-marker" aria-hidden="true"></i> {{(costume.state.length>3)? (costume.state | slice:0:3)+'..':(costume.state) }}</p>
                                            </div>
                                          </div>
                                        </div>
                                   </div>
                            </ng-container>
                        </div>
                    </div>
                </div> <!-- end hidden-body -->
            </div> <!-- end hide-slide -->
            <!-- <div *ngFor="let costume of costumes" [ngClass]="{'is-active': costume.isActive}">
                <a class="btn-primary" (click)="costume.isActive = !costume.isActive" >
                    Newest Addition
                </a>
                
                <div class="hide-slide" [ngClass]="{'active':costume.isActive}">
                    <div class="hidden-body" [@miniHomeSlideInOutAnimation] *ngIf="costume.isActive">
                       <div class="costume-card" >
                          <a [routerLink]="['/search/view/', costume.id]"><img style="width: 290px;" src="{{costume.image}}" alt="Costume Grab"></a>
                          <h4 class="costume-name">{{(costume.name.length>13)? (costume.name | slice:0:13)+'..':(costume.name) }}</h4>
                          <div class="costume-footer">
                            <div class="price">
                              <h4>${{costume.listingprice}}</h4>
                            </div>
                            <div class="stats pull-right">
                              <p class="category"><i class="fa fa-map-marker" aria-hidden="true"></i> {{(costume.state.length>3)? (costume.state | slice:0:3)+'..':(costume.state) }}</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <!-- <div class="container">
        <div class="row text-center">
            <div class="col-md-4">
                <a [routerLink]="['/search']" class="btn-primary">Buy</a>
            </div>
            <div class="col-md-4"> <a class="btn-primary" (click)="activateIt = !activateIt" >
            Newest Additions</a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/costumes']" class="btn-primary">Sell</a>
            </div>
        </div>
    </div> -->
    <div class="container btn-down">
        <div class="row">
            <div class="col-md-12 text-center">
                <a style="display: inline-block;" href="https://itunes.apple.com/us/app/costumegrab/id1422062326?mt=8"><img style="max-width: 130px; width: 100%;margin: 0 auto;position: relative;" src="../../../../assets/images/appstore.png" alt="Costume Grab">
                </a>
                <a style="display: inline-block;margin-left:1%;" href="https://play.google.com/store/apps/details?id=org.nativescript.CostumeGrab"><img style="max-width: 110px; width: 100%;margin: 0 auto;position: relative; " src="../../../../assets/images/playstore.png" alt="Costume Grab">
                </a>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-4">
                <a [routerLink]="['/search']" class="btn-primary">Buy</a>
            </div>
            <div class="col-md-4"> <a class="btn-primary newest-addition" (click)="activateIt = !activateIt" >
            Newest Additions</a>
            </div>
            <div class="col-md-4">
                <a [routerLink]="['/costumes']" class="btn-primary">Sell</a>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="our-mission text-center">
                    <h1>Our Mission</h1>
                    <div class="separator separator-danger">✻</div>
                    <p>To provide an easy solution for linking sellers and buyers with all the costumes they love for every type of event there is!</p>
                </div>

            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-4">
                <div class="contain-icons text-center">
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                    <h2>Upload Your Costume</h2>
                    <p>Take a picture from whatever device you want and upload your costumes for FREE.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="contain-icons text-center">
                    <i class="fa fa-money" aria-hidden="true"></i>
                    <h2>Get Paid</h2>
                    <p>Receive messages from potential buyers and negotiate the terms of payment (i.e. Paypal)</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="contain-icons text-center">
                    <i class="fa fa-money" aria-hidden="true"></i>
                    <h2>Ship Your Item</h2> 
                    <p>Once you've received paymnet, ship your item to your buyer for a win win situation!</p>
                </div>
            </div>
        </div> -->
        <!-- <div class="row">
            <div *ngIf="costumes">
                <ng-container *ngFor="let costume of costumes">
                    <div *ngIf="costume.is_active" class="col-md-4">
                        <a href="/search/view/{{costume.id}}"><img src="{{costume.image}}" alt="Costume Grab"></a>
                    </div>
                </ng-container>
            </div>
        </div>  --><!-- end row -->
    </div><!--  end container -->
    
    <div class="light-gray-bg">
        <div class="container">
            <!-- <div class="row">
                <div class="col-md-12">
                    <div class="our-mission text-center">
                        <h1>Ask Around</h1>
                        <div class="separator separator-danger">✻</div>
                        <p>To provide and easy solution for linking sellers and buyers with all the costumes they love for every type of event their is!</p>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-4">
                    <div class="testimonial">
                        <div class="image-contain">
                            <img src="../../../../assets/images/natalie.jpg" alt="Costume Grab">
                        </div>
                        <div class="personal text-center"> 
                            <h3 class="title">Natalie</h3>
                            <p class="location text-center">Portland, OR</p>          
                            <p class="spoken">Everything went smooth and I got a great new costume for my event!</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="testimonial">
                        <div class="image-contain">
                            <img src="../../../../assets/images/crystal.jpg" alt="Costume Grab">
                        </div>
                        <div class="personal text-center"> 
                            <h3 class="title">Crystal</h3>
                            <p class="location text-center">San Diego, CA</p>          
                            <p class="spoken">An easy, simple, 3 step process. Looking forward to selling more stuff out of my closet!</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="testimonial">
                        <div class="image-contain">
                            <img src="../../../../assets/images/damian.jpg" alt="Costume Grab">
                        </div>
                        <div class="personal text-center"> 
                            <h3 class="title">Damian</h3> 
                            <p class="location text-center">Pismo Beach, CA</p>         
                            <p class="spoken">My son and daughter both got great costumes. Very easy to arrange everything.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="our-mission text-center">
                    <h1>3 Easy Steps</h1>
                    <div class="separator separator-danger">♦</div>
                    <p>Simply sign up for free, add your costume, and wait for buyers to contact you.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container push-step text-center">
        <div class="circle">
            <i class="fa fa-cloud-upload" aria-hidden="true"></i>
        </div>
        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
        <div class="circle">
            <i class="fa fa-money" aria-hidden="true"></i>
        </div>
        <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
        <div class="circle special-align">
            <img class="shipping-box" src="../../../../assets/images/shipping-box.png" alt="Costume Grab">
        </div>
    </div>
    <div class="container push-step">
        
        <div class="row">
           <!--  <div class="col-md-3">
                <div class="contain-icons text-center">
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                    <h2>Sign up</h2>
                    <p>Sign up for free and creating your listing in five minutes.</p>
                </div>
            </div> -->
            <div class="col-md-4">
                <div class="contain-icons text-center">
                    <!-- <i class="fa fa-cloud-upload" aria-hidden="true"></i> -->
                    <h2>Upload Your Costume</h2>
                    <p>Take a picture from whatever device you want and upload your costumes for FREE.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="contain-icons text-center">
                    <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                    <h2>Get Paid</h2>
                    <p>Receive messages from potential buyers and negotiate the terms of payment (i.e. Paypal)</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="contain-icons text-center">
                    <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                    <h2>Ship Your Item</h2> 
                    <p>Once you've received payment, ship your item to your buyer for a win win situation!</p>
                </div>
            </div>
        </div>
    </div>
</div><!--  end white background -->
