<div class="gray-background clearfix">
  <div *ngIf="saveError">
    {{saveError}}
  </div>
  <div class="z-index">
      <div class="sidebar">
        <input type="text" class="search-input" placeholder="Search (i.e. name, zipcode)" [(ngModel)]="term">
        <div class="contain-slider">
          <div *ngIf="someKeyboardConfig">
            <p class="text-center">$ Price</p>
            <nouislider [ngStyle]="{'z-index': '0 !important'}" [tooltips]="[ true,true ]" [connect]="true" [(ngModel)]="someRange" [config]="someKeyboardConfig" (ngModelChange)="onChange($event)"></nouislider>
          </div>
        </div>
        <div *ngIf="genders">
          <ul class="ul-sort">
            <li *ngFor="let gender of genders">
              <input type="checkbox" (change)="searchGender(gender, $event)"  />  {{gender}}
            </li>
          </ul>
        </div>
        <div *ngIf="sizes">
          <ul class="ul-sort">
            <li *ngFor="let size of sizes">
              <input type="checkbox" (change)="searchSize(size, $event)"  />  {{size.name}}
            </li>
          </ul>
        </div>
        <div *ngIf="ages">
          <ul class="ul-sort">
            <li *ngFor="let age of ages">
              <input type="checkbox" (change)="searchAge(age, $event)"  />  {{age.name}}
            </li>
          </ul>
        </div>
      </div>

    <div class="set-width clearfix" style="padding-bottom: 100px;"> 
      <!-- <div class="fixed-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <input type="text" [(ngModel)]="term">
            </div>
            <div class="col-md-2">
              <div style="margin-top: 37px;">
                <nouislider [ngStyle]="{'z-index': '0 !important'}" [tooltips]="[ true,true ]" [connect]="true" [min]="0" [max]="500" [(ngModel)]="someRange" (ngModelChange)="onChange($event)"></nouislider>
              </div>
            </div>
            <div class="col-md-2">  
              <div *ngIf="genders">
                <ul class="ul-sort">
                  <p class="title">Gender:</p>
                  <li *ngFor="let gender of genders">
                    <input type="checkbox" (change)="searchGender(gender, $event)"  />  {{gender}}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-2">
              <div *ngIf="sizes">
                <ul class="ul-sort">
                  <p class="title">Sizes:</p>
                  <li *ngFor="let size of sizes">
                    <input type="checkbox" (change)="searchSize(size, $event)"  />  {{size.name}}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-2">
              <div *ngIf="ages">
                <ul class="ul-sort">
                  <p class="title">Ages:</p>
                  <li *ngFor="let age of ages">
                    <input type="checkbox" (change)="searchAge(age, $event)"  />  {{age.name}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="container padding-top">
          <div *ngIf="costumes">
              <div class="row">
                <ng-container *ngFor="let costume of costumes | costumesFilter: thePriceArray : selectedSizes: selectedAges : selectedGenders | filter:term">
                  <div *ngIf="costume.is_active" class="col-xl-3 col-lg-3 col-md-6">
                    <a (click)="overflowBody()" [routerLink]="['view', costume.id]">
                      <div class="costume-card" >
                        <img style="width: 100%;" src="{{costume.image}}" alt="">
                        <!-- <img style="width: 100%;" [defaultImage]="defaultImage" [lazyLoad]="costume.image" [offset]="offset" alt=""> -->
                        <h4 class="costume-name">{{(costume.name.length>13)? (costume.name | slice:0:13)+'..':(costume.name) }}</h4>
                        <div class="costume-footer">
                          <div class="price">
                            <h4>${{costume.listingprice}}</h4>
                          </div>
                          <div class="stats pull-right">
                            <p class="category"><i class="fa fa-map-marker" aria-hidden="true"></i> {{(costume.state.length>3)? (costume.state | slice:0:3)+'..':(costume.state) }}</p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </ng-container>
              </div> <!-- end row -->
          </div> <!-- end costumes -->
        </div> <!--  end container -->
      <div> <!-- endset-width clearfix -->
    </div> <!-- z-index -->
  </div> <!-- end gray background -->
  <div class="view-side-form">
      <router-outlet (activate)="addBodyOverflow()"  (deactivate)="removeBodyOverflow()"></router-outlet>
  </div>
