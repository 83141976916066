<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<!--      
Sliding transitions for routes
<nav>
      <a routerLink="/home" routerLinkActive="active">Home</a>
      <a routerLink="/search" routerLinkActive="active">search</a>
      <a routerLink="/login" routerLinkActive="active">Login</a>
      <a routerLink="/register" routerLinkActive="active">Register</a>
    </nav>
	<main [@routerTransition]="getState(o)">
	<router-outlet #o="outlet"></router-outlet>
</main>
 -->

