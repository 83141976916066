<div class="grey-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="white-card">
					<h1>Terms of Service:</h1>
					<div class="separator separator-danger">♦</div>
					<h3>1. Acceptance of Terms</h3>

					<p>Costumegrab (“Costumegrab”) provides its Service (as defined below) to you through its website located at https://www.costumegrab.com/ (the “Site”), subject to this Terms of Service agreement (“TOS”). By accepting this TOS or by accessing or using the Service or Site, you acknowledge that you have read, understood, and agree to be bound by this TOS. If you are entering into this TOS on behalf of a company, business or other legal entity, you represent that you have the authority to bind such entity and its affiliates to this TOS, in which case the terms “you” or “your” shall refer to such entity and its affiliates. If you do not have such authority, or if you do not agree with this TOS, you must not accept this TOS and may not use the Service.</p>

					<p>Costumegrab may change this TOS from time to time without prior notice. You can review the most current version of this TOS at any time at https://www.costumegrab.com/terms-of-service. The revised terms and conditions will become effective upon posting, and if you use the Service after that date, your use will constitute acceptance of the revised terms and conditions. If any change to this TOS is not acceptable to you, your only remedy is to stop accessing and using the Service.</p>

					<h3>2. Description of Service</h3>

					<p>The “Service” includes (a) the Site, (b) Costumegrab’s services and related technologies to help customers (“Customers”) receive and send in clothing, and (c) all software (including the Software, as defined below), data, reports, text, images, sounds, video, and content made available through any of the foregoing (collectively referred to as the “Content”). Any new features added to or augmenting the Service are also subject to this TOS.</p>

					<h3>3. General Conditions/ Access and Use of the Service</h3>

					<p>You are required to register with Costumegrab in order to access and use certain features of the Service. If you choose to register for the Service, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Service’s registration form. Registration data and certain other information about you are governed by our Privacy Policy.</p>

					<p>Subject to the terms and conditions of this TOS, you may access and use the Service only for lawful purposes. All rights, title and interest in and to the Service and its components will remain with and belong exclusively to Costumegrab. You shall not (a) sublicense, resell, rent, lease, transfer, assign, time share or otherwise commercially exploit or make the Service available to any third party; (b) use the Service in any unlawful manner (including without limitation in violation of any data, privacy or export control laws) or in any manner that interferes with or disrupts the integrity or performance of the Service or its components, or (c) modify, adapt or hack the Service to, or otherwise attempt to gain unauthorized access to the Service or its related systems or networks. Unless you are a registered Partner, the Service is for your personal use and may not be used for direct commercial endeavors. You shall comply with any codes of conduct, policies or other notices Costumegrab provides you or publishes in connection with the Service, and you shall promptly notify Costumegrab if you learn of a security breach related to the Service.</p>

					<p>Any software that may be made available by Costumegrab in connection with the Service (“Software”) contains proprietary and confidential information that is protected by applicable intellectual property and other laws. Subject to the terms and conditions of this TOS, if you are a Partner of Costumegrab, Costumegrab hereby grants you a personal, non-transferable, non-sublicensable and non-exclusive right and license to use the Software on the website URLs you provide in your Partner Account solely in connection with the Service, provided that, except for the source code of software made available by Costumegrab pursuant to an open source license, you shall not (and shall not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code or sell, assign, sublicense or otherwise transfer any right in any Software. You agree not to access the Service by any means other than through the interface that is provided by Costumegrab for use in accessing the Service. Any rights not expressly granted herein are reserved and, except as expressly provided herein, no license or right to use any trademark of Costumegrab or any third party is granted to you in connection with the Service.</p>

					<p>You are solely responsible for all data, information, feedback, suggestions, text, content and other materials that you upload, post, deliver, provide or otherwise transmit or store (hereafter “post(ing)”) in connection with or relating to the Service (“Your Content”), whether as a Partner, end-user or otherwise. You are responsible for maintaining the confidentiality of your login, password and account and for all activities that occur under your login or account. Costumegrab reserves the right to access your account in order to respond to your requests for technical support. By posting Your Content on or through the Service, You hereby do and shall grant Costumegrab a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable and transferable license to use, modify, reproduce, distribute, display, publish and perform Your Content in connection with the Service. Costumegrab has the right, but not the obligation, to monitor the Service, Content, or Your Content. You further agree that Costumegrab may remove or disable any Content at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content), or for no reason at all.</p>

					<p>You understand that the operation of the Service, including Your Content, may be unencrypted and involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices and (c) transmission to Costumegrab’s third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate and maintain the Service. Accordingly, you acknowledge that you bear sole responsibility for adequate security, protection and backup of Your Content. Costumegrab will have no liability to you for any unauthorized access or use of any of Your Content, or any corruption, deletion, destruction or loss of any of Your Content.</p>

					<p>The Service may provide, or third parties such as Partners may provide, links or other access to other sites, and resources on the Internet. Costumegrab has no control over such sites and resources, nor Partner sites and resources, and Costumegrab is not responsible for and does not endorse such sites and resources. Such third parties or the applicable Partner, and not Costumegrab, are the providers of other sites and resources and are solely responsible for providing any goods or services you purchase on third party or Partner sites (“Third Party Offers”). You further acknowledge and agree that Costumegrab shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, or Third Party Offers available on or through any such site or resource. Any dealings you have with third parties (including Partners) found while using the Service are between you and the third party, and you agree that Costumegrab is not liable for any loss or claim that you may have against any such third party.</p>

					<p>You may also enable various online services (such as social media or networks sites) to be directly integrated into your Costumegrab experience. By directly integrating these services into the Service, we make the Costumegrab online experiences richer and more personalized. To take advantage of these features, Costumegrab may ask you to register for or log into the third party services on the websites of their respective providers. By enabling third party services within the Service, you are allowing Costumegrab to pass your log-in information to these service providers for this purpose. For more information about the implications of activating these third party services and Costumegrab’s use, storage and disclosure of information related to you and your use of such services within Costumegrab, please see our Privacy Policy. However, please remember that the manner in which third party services use, store and disclose your information is governed solely by the policies of such third parties, and Costumegrab shall have no liability or responsibility for the privacy practices or other actions of any third party site or service that may be enabled within the Service. Further, Costumegrab is not responsible for the accuracy, availability or reliability of any information, content, goods, data, opinions, advice or statements made available in connection with third party sites or services. As such, Costumegrab is not liable for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such third party site or service. Costumegrab enables these features merely as a convenience and the inclusion of such features does not imply an endorsement or recommendation.</p>

					<p>The failure of Costumegrab to exercise or enforce any right or provision of this TOS shall not be a waiver of that right. You acknowledge that this TOS is a contract between you and Costumegrab, even though it is electronic and is not physically signed by you and Costumegrab, and it governs your use of the Service and takes the place of any prior agreements between you and Costumegrab.</p>

					<p>If you are a Partner, Costumegrab reserves the right to use your name and/or company name as a reference for marketing or promotional purposes on Costumegrab’s website and in other communication with existing or potential Costumegrab partners. To decline Costumegrab this right you need to email info@costumegrab.com stating that you do not wish to be used as a reference.</p>

				<!-- 	<h3>4. Payment.</h3>

					To the extent the Service or any portion thereof is made available for any fee, you will be required to use Authorize.net. You agree to pay Costumegrab the amount that is specified in the payment plan in accordance with the terms of such plan and this TOS. If you dispute any charges you must let Costumegrab know within sixty (60) days after the date that Costumegrab from the date of payment. We reserve the right to change Costumegrab’s prices. If Costumegrab does, Costumegrab will provide notice of the change on the Site, at a reasonable time before the change is to take effect. Your continued use of the Service after the price change becomes effective constitutes your agreement to pay the changed amount. -->

					<h3>5. Representations and Warranties</h3>

					<p>You represent and warrant to Costumegrab that (i) you have full power and authority to enter into this TOS; (ii) you own all Your Content or have obtained all permissions, releases, rights or licenses required to engage in your billing, posting and other activities (and allow Costumegrab to perform its obligations) in connection with the Services without obtaining any further releases or consents; (iii) Your Content and other activities in connection with the Service, and Costumegrab’s exercise of all rights and license granted by you herein, do not and will not violate, infringe, or misappropriate any third party’s copyright, trademark, right of privacy or publicity, or other personal or proprietary right, nor does Your Content contain any matter that is defamatory, obscene, unlawful, threatening, abusive, tortious, offensive or harassing; and (iv) you are of legal age to purchase or sell the types of items represented on the costumegrab.com website.</p>

					<h3>6. Termination</h3>

					<p>You have the right to terminate your account at any time by sending an email to info@costumegrab.com. Costumegrab reserves the right to (i) modify or discontinue, temporarily or permanently, the Service (or any part thereof) and (ii) refuse any and all current and future use of the Service, suspend or terminate your account (any part thereof) or use of the Service and remove and discard any of Your Content in the Service, for any reason, including if Costumegrab believes that you have violated this TOS. Costumegrab shall not be liable to you or any third party for any modification, suspension or discontinuation of the Service. Costumegrab will use good faith efforts to contact you to warn you prior to suspension or termination of your account by Costumegrab. All of Your Content on the Service (if any) may be permanently deleted by Costumegrab upon any termination of your account in its sole discretion. All accrued rights to payment and the terms of Section 4-12 shall survive termination of this TOS, and all fees paid to Costumegrab are non-refundable.</p>

					<h3>7. DISCLAIMER OF WARRANTIES</h3>

					<p>THE SERVICE, INCLUDING THE SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND Costumegrab EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT Costumegrab DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE AND NO INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU FROM Costumegrab OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS TOS.</p>

					<h3>8. LIMITATION OF LIABILITY</h3>

					<p>UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR OTHERWISE) SHALL Costumegrab BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA OR BUSINESS INTERRUPTION, OR (B) FOR ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES IN EXCESS OF THE FEES ACTUALLY PAID BY YOU IN THE SIX (6) MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO FEES APPLY, ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THIS TOS BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THIS TOS.</p>

					<p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply to you. IN THESE STATES, Costumegrab’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>

					<h3>9. Indemnification</h3>

					<p>You shall defend, indemnify, and hold harmless Costumegrab from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, arising or resulting from your breach of this TOS, any of Your Content, or your other access, contribution to, use or misuse of the Service. Costumegrab shall provide notice to you of any such claim, suit or demand. Costumegrab reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting Costumegrab’s defense of such matter.</p>

					<h3>10. Third Party Offers</h3>

					<p>If you are a Partner, you are the holder and issuer of each Third Party Offer you make available in connection with the Service. As a holder and issuer of the Third Party Offer, Partner represents, warrants and agrees that it shall fully honor and redeem all Third Party Offers and shall be solely and fully responsible for any and all injuries, illnesses, damages, claims, liabilities, losses and costs suffered by or related to a customer concerning any Third Party Offer or caused in whole or in part by Partner. Each Partner hereby waives, and releases and will hold harmless Costumegrab and its officers, directors, employees and agents from, any claim, liabilities, losses, damages, or injury arising from or related to any Third Party Offer or any act or omission of Partner in connection with a Third Party Offer or the services or goods provided in connection therewith and/or as it relates to compliance with applicable laws.</p>

					<h3>11. Assignment</h3>

					<p>You may not assign this TOS without the prior written consent of Costumegrab, but Costumegrab may assign or transfer this TOS, in whole or in part, without restriction.</p>

					<h3>12. Governing Law</h3>

					<p>This TOS shall be governed by the laws of the State of California without regard to the principles of conflicts of law. Unless otherwise elected by Costumegrab in a particular instance, you hereby expressly agree to submit to the exclusive personal jurisdiction of the federal and state courts of San Diego County, California for the purpose of resolving any dispute relating to your access to or use of the Service.</p>

					<h3>13. Privacy</h3>

					<p>Please see our Privacy Policy to understand how Costumegrab collects and uses personal information.</p>
				</div>
			</div>
		</div>
	</div>
</div>	
