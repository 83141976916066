<div class="grey-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="white-card">
					<h1>Contact</h1>
					<div class="separator separator-danger">✻</div> 
					<i class="fa fa-envelope"></i>
					<p>For any questions or concerns, please email <a href="mailto:info@costumegrab.com">info@costumegrab.com</a>.</p>
					<p>We will try our best to respond to every request within 24 hours and above all,</p>
					<p>we look forward to hearing from you!</p>
				</div>
			</div>
		</div>
	</div>
</div>	
