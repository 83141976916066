<div class="grey-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="white-card">
					<h1>Tips for Buyers</h1>
					<div class="separator separator-danger">✻</div> 
					<div class="seller-faq-contain">
						<p><span class="bold-700">I'm interested in buying a costume, what do I do now?</span> That's great! You are on you way to being the life of the party, comic con, or any dress-up event there is!  The first step is to click on "Contact Seller" and express your interest. You can offer a price, or simply ask questions or post comments to the seller.  Once a price agreement is made, it is usually best to make payments by PayPal.</p>

						<p><span class="bold-700">Who will pay for shipping and what does it cost?</span> The buyer and seller will determine who pays the shipping cost (you, the seller, or shared).  Commonly sellers will include the shipping cost in the asking price and inform the buyer at the time of sale. Shipping costs vary depending on size, weight, and shipping method.  Make sure that the seller provides you with the shipping tracking information.</p>

						<p><span class="bold-700">Do they have a return policy?</span> It is up to the seller to offer the return policy. If they do have one, they may expect you to: (1) notify them within 24 of receipt that you wish to return the item; (2) require you to ship the item back immediately; (3) they may require you to pay a return fee of 15% of the purchase price of the item.</p>

						<h2>Simple Tips For Buying Online</h2>

						<p>Buying and selling online can be a very fun and profitable experience, but above all, when buying or selling any items online, you need to use your <span class="bold-700">common sense</span>.  While Costume Grab is currently doing all we can to eliminate scammers and spammers from our website, both buyer and sellers need to do their part in avoiding these types of people.  Here are some tips for both sellers and buyers.</p>

						<p>1. <span class="bold-700">Always use a secure payment method</span>. At Costume Grab we recommend using PayPal to accept payments. If you are unfamiliar with Paypal, you can read more about how it works <a href="https://www.paypal.com/webapps/mpp/pay-online">here</a>.</p>

						<p>2. <span class="bold-700">NEVER</span> wire transfer funds.  If the seller asks you to wire transfer funds, this is a red flag that they are a scammer.</p>

						<p>3. <span class="bold-700">NEVER</span>, under any circumstances, should you give out your personal information such as bank account numbers, credit cards, social security number, account passwords, etc.</p>

						<p>4. If you choose to meet a seller in person to exchange money, arrange the meeting in a public area. A great choice is a local coffee shop. Consider bringing a friend with you.</p>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>	
