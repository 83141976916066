<div class="grey-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="white-card">
					<h1>FAQ</h1>
					<div class="separator separator-danger">✻</div> 
					<div class="seller-faq-contain">
						<h2>Questions for Sellers</h2>
						<div class="top-title" (click)="showContent = !showContent;">
							<i [ngClass]="{ 'show-content': showContent, 'hide-content': !showContent}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showContent, 'hide-content': showContent}" class="fa fa-plus"></i>
							<h3>Why should I sell all my costumes that I love and have had forever?</h3>
							<p [ngClass]="{ 'show-content': showContent, 'hide-content': !showContent  }">Unfortunately, a lot of the time those costumes end up cluttering your closet, never to be worn again.  Instead of letting those costumes get old and dingy, why not share them with the world and make a little money on the side? You get to know your costume will be enjoyed by someone else, while putting money in your pocket.</p>
						</div>
						<div class="top-title" (click)="showContent2 = !showContent2;">
							<i [ngClass]="{ 'show-content': showContent2, 'hide-content': !showContent2}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showContent2, 'hide-content': showContent2}" class="fa fa-plus"></i>
							<h3>What are some tips for listing costumes?</h3>
							<p [ngClass]="{ 'show-content': showContent2, 'hide-content': !showContent2  }">
								Look under <a [routerLink]="['/seller-tips']">Tips for Sellers</a>.
							</p>
						</div>
						<div class="top-title" (click)="showContent3 = !showContent3;">
							<i [ngClass]="{ 'show-content': showContent3, 'hide-content': !showContent3}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showContent3, 'hide-content': showContent3}" class="fa fa-plus"></i>
							<h3>So who pays shipping and what does it cost?</h3>
							<p [ngClass]="{ 'show-content': showContent3, 'hide-content': !showContent3  }">
								As a seller, you may determine who pays the shipping cost (the buyer, you, or shared).  Commonly sellers will include the shipping cost in the asking price and inform the buyer at the time of sale. Shipping costs vary depending on size, weight, and shipping method.
							</p>
						</div>
						<div class="top-title" (click)="showContent4 = !showContent4;">
							<i [ngClass]="{ 'show-content': showContent4, 'hide-content': !showContent4}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showContent4, 'hide-content': showContent4}" class="fa fa-plus"></i>
							<h3> Somebody wants to buy my costume! What now?</h3>
							<p [ngClass]="{ 'show-content': showContent4, 'hide-content': !showContent4  }">
								Check <a [routerLink]="['/seller-tips']">Tips for Sellers</a>.
							</p>
						</div>
						<div class="top-title" (click)="showContent5 = !showContent5;">
							<i [ngClass]="{ 'show-content': showContent5, 'hide-content': !showContent5}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showContent5, 'hide-content': showContent5}" class="fa fa-plus"></i>
							<h3>Can I list costumes if I live outside of the US?</h3>
							<p [ngClass]="{ 'show-content': showContent5, 'hide-content': !showContent5  }">
								Absolutely.  As of right now, the costume entries are tailored for people living in the US, but try your best to fit your location (outside of the US) and costume sizes into the parameters given and there shouldn't be any confusion. The Costume Grab team is currently working on making the entries global.
							</p>
						</div>
						<div class="top-title" (click)="showContent6 = !showContent6;">
							<i [ngClass]="{ 'show-content': showContent6, 'hide-content': !showContent6}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showContent6, 'hide-content': showContent6}" class="fa fa-plus"></i>
							<h3>Will Costume Grab give out my information to third parties?</h3>
							<p [ngClass]="{ 'show-content': showContent6, 'hide-content': !showContent6  }">
								No. We will always respect your privacy. Please read our <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.
							</p>
						</div>
						<div class="top-title" (click)="showContent7 = !showContent7;">
							<i [ngClass]="{ 'show-content': showContent7, 'hide-content': !showContent7}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showContent7, 'hide-content': showContent7}" class="fa fa-plus"></i>
							<h3>Do you think I should offer a return policy?</h3>
							<p [ngClass]="{ 'show-content': showContent7, 'hide-content': !showContent7  }">
								See <a [routerLink]="['/seller-tips']">Tips for Sellers</a>.
							</p>
						</div>
					</div>
					<div class="seller-faq-contain extra-margin">
						<h2>Questions for Buyers</h2>
						
						<div class="top-title" (click)="showSeller = !showSeller;">
							<i [ngClass]="{ 'show-content': showSeller, 'hide-content': !showSeller}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showSeller, 'hide-content': showSeller}" class="fa fa-plus"></i>
							<h3>Why buy a new or pre-owned costume?</h3>
							<p [ngClass]="{ 'show-content': showSeller, 'hide-content': !showSeller  }">
								If you're a costume lover like we are, this question really doesn't need to be asked, but in case your on the fence, there are thousands of amazing costumes out there that people have worn barely once and have kept them stored away in great condition. Instead of spending time scouring the internet for the best cosplay, Halloween, or any type of themed dress up party, you might just find someone with the perfect costume, right in your area.  What's more, is you will save about 50% of the costs of local costume stores.
							</p>
						</div>
						<div class="top-title" (click)="showSeller2 = !showSeller2;">
							<i [ngClass]="{ 'show-content': showSeller2, 'hide-content': !showSeller2}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showSeller2, 'hide-content': showSeller2}" class="fa fa-plus"></i>
							<h3>Who pays shipping and what does it cost?</h3>
							<p [ngClass]="{ 'show-content': showSeller2, 'hide-content': !showSeller2  }">
								The buyer and seller will determine who pays the shipping cost (you, the seller, or shared).  Commonly sellers will include the shipping cost in the asking price and inform the buyer at the time of sale. Shipping costs vary depending on size, weight, and shipping method.
							</p>
						</div>
						<div class="top-title" (click)="showSeller3 = !showSeller3;">
							<i [ngClass]="{ 'show-content': showSeller3, 'hide-content': !showSeller3}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showSeller3, 'hide-content': showSeller3}" class="fa fa-plus"></i>
							<h3>Will Costume Grab give out my information to third parties?</h3>
							<p [ngClass]="{ 'show-content': showSeller3, 'hide-content': !showSeller3  }">
								No. We will always respect your privacy. Please read our <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.
							</p>
						</div>
						<div class="top-title" (click)="showSeller4 = !showSeller4;">
							<i [ngClass]="{ 'show-content': showSeller4, 'hide-content': !showSeller4}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showSeller4, 'hide-content': showSeller4}" class="fa fa-plus"></i>
							<h3>What is the return policy on items?</h3>
							<p [ngClass]="{ 'show-content': showSeller4, 'hide-content': !showSeller4  }">
								Every costume on the Costume Grab site is for sale by their individual owner. Therefore, you should ask the seller what their policy is. Some sellers may offer returns, while other sellers do not. Costume Grab is not liable for any shipping issues.
							</p>
						</div>
						<div class="top-title" (click)="showSeller5 = !showSeller5;">
							<i [ngClass]="{ 'show-content': showSeller5, 'hide-content': !showSeller5}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showSeller5, 'hide-content': showSeller5}" class="fa fa-plus"></i>
							<h3>What if I receive an item that was not as described?</h3>
							<p [ngClass]="{ 'show-content': showSeller5, 'hide-content': !showSeller5  }">
								The first thing to do is to contact the seller and explain why the item you received was not as it was described.  Refund requests can be processed through PayPal’s Purchase Protection Program. Keep in mind, Costume Grab is only a marketplace to sell and buy costumes and accessories. As such, we are not responsible for any buyer and seller actions.
							</p>
						</div>
						<div class="top-title" (click)="showSeller6 = !showSeller6;">
							<i [ngClass]="{ 'show-content': showSeller6, 'hide-content': !showSeller6}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showSeller6, 'hide-content': showSeller6}" class="fa fa-plus"></i>
							<h3>What if I do not receive the item?</h3>
							<p [ngClass]="{ 'show-content': showSeller6, 'hide-content': !showSeller6  }">
								The first thing to do is to contact the seller and ask when you are expected to recieve the item.  (1) You should ask the seller for shipment tracking information; (2) That the seller give you a refund; or (3) consult PayPal’s Purchase Protection Program.  If you have additional question or technical difficulties, please feel free to contact Costume Grab at info@costumegrab.com.
							</p>
						</div>
						<div class="top-title" (click)="showSeller7 = !showSeller7;">
							<i [ngClass]="{ 'show-content': showSeller7, 'hide-content': !showSeller7}" class="fa fa-minus"></i>
							<i [ngClass]="{ 'show-content': !showSeller7, 'hide-content': showSeller7}" class="fa fa-plus"></i>
							<h3>Will I be able to cancel a transaction?</h3>
							<p [ngClass]="{ 'show-content': showSeller7, 'hide-content': !showSeller7  }">
								It's possible, however, if you do not cancel immediately, the seller may have already shipped the item and removed the listing from Costume Grab. That would cause them to lose the opportunity to sell the item to another buyer. All cancellations will be resolved between buyer and seller.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>	
